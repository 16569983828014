import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { administrationMenuEntityList, Agent, campaignMenuEntityList, Entity } from 'ag-common-lib/public-api';
import { AdminCrmRoutes } from 'src/app/admin-crm/admin-crm.model';
import { AppRoutes } from 'src/app/app.model';
import { map, Observable } from 'rxjs';
import { CampaignsRoutes } from 'src/app/ag-campaigns/ag-campaigns.model';
import { ARMAuthService } from 'src/app/shared/services/util/arm-auth.service';
import { NotificationsHubRoutes } from 'src/app/ag-notifications-hub/ag-notifications-hub.model';
import { InitializedEvent } from 'devextreme/ui/toolbar';
import { hasPermissionGuard } from 'ag-common-svc/shared/utils/has-permission-guard';

const optionWidth = 230;
const dropDownWidth = 160;

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  contactsBoxOptions$: Observable<any>;
  // emailBoxOptions$: Observable<any>;
  notificationsBoxOptions$: Observable<any>;
  registrationBoxOptions$: Observable<any>;
  campaignsBoxOptions$: Observable<any>;
  reportsBoxOptions$: Observable<any>;

  adminBoxOptions$: Observable<any>;
  userBoxOptions$: Observable<any>;

  agent$: Observable<Agent>;

  constructor(
    private authService: ARMAuthService,
    private router: Router,
  ) {
    this.userBoxOptions$ = this.authService.loggedInAgent$.pipe(
      map(agent => {
        return {
          dropDownOptions: {
            title: agent?.p_email,
            showTitle: true,
            width: 300,
            wrapperAttr: { class: 'header__user-drop-down ' },
          },
          items: ['Profile', 'Logout'],
          icon: 'agi-user',

          onItemClick: (args: any) => {
            this.navigateTo(args.itemData);
          },
        };
      }),
    );

    this.adminBoxOptions$ = this.authService.armPermissions$.pipe(
      map(permissions => {
        return {
          width: dropDownWidth,
          dropDownOptions: { width: optionWidth },
          visible: hasPermissionGuard(administrationMenuEntityList, permissions),
          items: [
            {
              value: AdminCrmRoutes.TaskListManager,
              description: 'Task Lists',
              visible: hasPermissionGuard([Entity.administrationTaskListManager], permissions),
            },
            {
              value: AdminCrmRoutes.ImportRulesManager,
              description: 'Import Rules',
              visible: hasPermissionGuard([Entity.administrationImportRulesManager], permissions),
            },
            {
              value: AdminCrmRoutes.LookupsManager,
              description: 'Lookups Manager',
              visible: hasPermissionGuard([Entity.administrationLookupsManager], permissions),
            },
            {
              value: AdminCrmRoutes.ErrorsManager,
              description: 'Errors Manager',
              visible: hasPermissionGuard([Entity.administrationErrorsManager], permissions),
            },
            {
              value: AdminCrmRoutes.LogsManager,
              description: 'Logs Manager',
              visible: hasPermissionGuard([Entity.administrationLogsManager], permissions),
            },
            {
              value: AdminCrmRoutes.UserRolesManager,
              description: 'User Roles Manager',
              visible: hasPermissionGuard([Entity.administrationUserManager], permissions),
            },
            {
              value: AdminCrmRoutes.ReportsManager,
              description: 'Reports Manager',
              visible: hasPermissionGuard([Entity.administrationReportsManager], permissions),
            },
            {
              value: AdminCrmRoutes.SalesGoals,
              description: 'Sales Goals',
              visible: hasPermissionGuard([Entity.agentSalesGoal], permissions),
            },
            {
              value: AdminCrmRoutes.RoleManager,
              description: 'Role Manager',
              visible: hasPermissionGuard([Entity.administrationRoleManager], permissions),
            },
            {
              value: AdminCrmRoutes.GoogleAuthManagement,
              description: 'Google Auth Management',
              visible: hasPermissionGuard([Entity.administrationGoogleAuthManager], permissions),
            },
            {
              value: AdminCrmRoutes.AuthStats,
              description: 'Auth Stats',
              visible: hasPermissionGuard([Entity.administrationAuthStats], permissions),
            },
            {
              value: AdminCrmRoutes.AgentMergeTool,
              description: 'Agent Merge Tool',
              visible: hasPermissionGuard([Entity.administrationMergeTool], permissions),
            },
          ],
          keyExpr: 'value',
          displayExpr: 'description',
          text: 'Administration',
          onItemClick: ({ itemData }: any) => {
            this.router.navigate([AppRoutes.Administration, itemData.value]);
          },
        };
      }),
    );

    this.contactsBoxOptions$ = this.authService.armPermissions$.pipe(
      map(permissions => {
        return {
          width: dropDownWidth,
          dropDownOptions: { width: optionWidth },
          visible: hasPermissionGuard(
            [Entity.agentAdminister, Entity.agencyAdminister, Entity.carrierCrm, Entity.prospect, Entity.registrants],
            permissions,
          ),
          items: [
            {
              value: AppRoutes.Agents,
              description: 'Agents',
              visible: hasPermissionGuard([Entity.agentAdminister], permissions),
            },
            {
              value: AppRoutes.Agencies,
              description: 'Agencies',
              visible: hasPermissionGuard([Entity.agencyAdminister], permissions),
            },
            {
              value: AppRoutes.Carriers,
              description: 'Carriers',
              visible: hasPermissionGuard([Entity.carrierCrm], permissions),
            },
            {
              value: AppRoutes.Prospects,
              description: 'Prospects',
              visible: hasPermissionGuard([Entity.prospect], permissions),
            },
            {
              value: AppRoutes.Registrants,
              description: 'Registrants',
              visible: hasPermissionGuard([Entity.registrants], permissions),
            },
          ],
          keyExpr: 'value',
          displayExpr: 'description',
          text: 'Contacts',
          onItemClick: ({ itemData }: any) => {
            this.router.navigate([itemData.value]);
          },
        };
      }),
    );

    // this.emailBoxOptions$ = this.authService.armPermissions$.pipe(
    //   map(permissions => {
    //     return {
    //       width: dropDownWidth,
    //       dropDownOptions: { width: optionWidth },
    //       visible: hasPermissionGuard([Entity.emailDistributionLists, Entity.emailTemplatesLists], permissions),
    //       items: [
    //         {
    //           value: 'Distribution-Lists',
    //           description: 'Distribution Lists',
    //           visible: hasPermissionGuard([Entity.emailDistributionLists], permissions),
    //         },
    //         {
    //           value: 'Email-Templates',
    //           description: 'Email Templates',
    //           visible: hasPermissionGuard([Entity.emailTemplatesLists], permissions),
    //         },
    //       ],
    //       keyExpr: 'value',
    //       displayExpr: 'description',
    //       text: 'Email',
    //       onItemClick: ({ itemData }: any) => {
    //         this.router.navigate([itemData.value]);
    //       },
    //     };
    //   }),
    // );

    this.notificationsBoxOptions$ = this.authService.armPermissions$.pipe(
      map(permissions => {
        return {
          width: dropDownWidth,
          dropDownOptions: { width: optionWidth },
          visible: hasPermissionGuard(
            [Entity.notificationsChannels, Entity.notificationsScheduler, Entity.notificationsTemplates],
            permissions,
          ),
          items: [
            {
              value: NotificationsHubRoutes.NotificationsTemplates,
              description: 'Notifications Templates',
              visible: hasPermissionGuard([Entity.notificationsTemplates], permissions),
            },
            {
              value: NotificationsHubRoutes.NotificationsChannels,
              description: 'Notifications Channels',
              visible: hasPermissionGuard([Entity.notificationsChannels], permissions),
            },
            {
              value: NotificationsHubRoutes.NotificationsScheduler,
              description: 'Notifications Scheduler',
              visible: hasPermissionGuard([Entity.notificationsScheduler], permissions),
            },
          ],
          keyExpr: 'value',
          displayExpr: 'description',
          text: 'Notifications Hub',
          onItemClick: ({ itemData }: any) => {
            this.router.navigate([AppRoutes.NotificationsHub, itemData.value]);
          },
        };
      }),
    );

    this.registrationBoxOptions$ = this.authService.armPermissions$.pipe(
      map(permissions => {
        return {
          width: dropDownWidth,
          dropDownOptions: { width: optionWidth },
          visible: hasPermissionGuard(
            [Entity.conference, Entity.conferenceRegistrants, Entity.conferenceListAdmin, Entity.conferenceList /* Entity.registrationsWebinars, Entity.registrationsContests */],
            permissions,
          ),
          items: [
            {
              value: 'Events',
              description: 'Events',
              visible: hasPermissionGuard([Entity.conference, Entity.conferenceListAdmin, Entity.conferenceList], permissions),
            },
            // {
            //   value: 'Webinars',
            //   description: 'Webinars',
            //   visible: hasPermissionGuard([Entity.registrationsWebinars], permissions),
            // },
            // {
            //   value: 'Contests',
            //   description: 'Contests',
            //   visible: hasPermissionGuard([Entity.registrationsContests], permissions),
            // },
            {
              value: 'conference-registrations',
              description: 'Event Registrations',
              visible: hasPermissionGuard([Entity.conferenceRegistrants], permissions),
            },
          ],
          keyExpr: 'value',
          displayExpr: 'description',
          text: 'Registrations',
          onItemClick: ({ itemData }: any) => {
            this.router.navigate([itemData.value]);
          },
        };
      }),
    );

    this.campaignsBoxOptions$ = this.authService.armPermissions$.pipe(
      map(permissions => {
        return {
          width: dropDownWidth,
          dropDownOptions: { width: optionWidth },
          visible: hasPermissionGuard(campaignMenuEntityList, permissions),
          items: [
            {
              value: CampaignsRoutes.CampaignServices,
              description: 'Campaign Services',
              visible: hasPermissionGuard([Entity.campaignServices], permissions),
            },
            {
              value: CampaignsRoutes.CampaignManagementTaskBoard,
              description: 'Task Board',
              visible: hasPermissionGuard([Entity.campaignTaskBoard], permissions),
            },
            {
              value: CampaignsRoutes.CampaignManagementTaskGrid,
              description: 'Task Grid',
              visible: hasPermissionGuard([Entity.campaignTaskGrid], permissions),
            },
          ],
          text: 'Campaigns',
          keyExpr: 'value',
          displayExpr: 'description',
          onItemClick: ({ itemData }) => {
            this.router.navigate([AppRoutes.Campaign, itemData.value]);
          },
        };
      }),
    );

    this.reportsBoxOptions$ = this.authService.armPermissions$.pipe(
      map(permissions => {
        return {
          width: dropDownWidth,
          dropDownOptions: { width: optionWidth },
          visible: hasPermissionGuard(
            [
              // Entity.reportStatusReport,
              // Entity.reportRegistrationReport,
              // Entity.reportEmailReport,
              Entity.reportChristmasCardList,
            ],
            permissions,
          ),
          items: [
            // {
            //   value: 'Status-Report',
            //   description: 'Status Report',
            //   visible: hasPermissionGuard([Entity.reportStatusReport], permissions),
            // },
            // {
            //   value: 'Registration-Report',
            //   description: 'Registration Report',
            //   visible: hasPermissionGuard([Entity.reportRegistrationReport], permissions),
            // },
            // {
            //   value: 'Email Report',
            //   description: 'Email Report',
            //   visible: hasPermissionGuard([Entity.reportEmailReport], permissions),
            // },
            {
              value: 'Christmas-Card-List',
              description: 'Christmas Card List',
              visible: hasPermissionGuard([Entity.reportChristmasCardList], permissions),
            },
          ],
          keyExpr: 'value',
          displayExpr: 'description',
          text: 'Reports',
          onItemClick: ({ itemData }) => {
            this.navigateTo(itemData.value);
          },
        };
      }),
    );
  }

  protected onToolbarInitialized = (e: InitializedEvent) => {
    setTimeout(() => {
      e.component.repaint();
    }, 700);
  };

  navigateTo(page: string) {
    let location: string;
    if (page == 'My Contacts') {
      location = 'Contacts';
    } else if (page == 'My Companies') {
      location = 'Companies';
    } else if (page == 'Distribution Lists') {
      location = 'Distribution-Lists';
    } else if (page == 'Email Templates') {
      location = 'Email-Templates';
    } /* if (page == 'Registration Report') {
      location = 'Registration-Reports';
    } else */ else if (page == 'Event Registrations') {
      location = 'conference-registrations';
    } else if (page == 'Profile') {
      location = 'profile';
    } else if (page == 'Logout') {
      this.authService.logOut();
      return;
    } else if (page == 'Christmas Card List') {
      location = 'Christmas-Card-List';
    } else if (page == 'Conference Posters List') {
      location = 'Conference-Posters-List';
    } else location = page;

    this.router.navigate([location]);
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  startSearch = () => {
    this.menuToggle.emit();
  };
}
