import { Pipe, PipeTransform } from '@angular/core';
import { AttendeeType } from 'ag-common-lib/lib/models/registration/attendees.model';

@Pipe({
  name: 'cellGroupInviteeCount',
})
export class CellGroupInviteeCountPipe implements PipeTransform {
  transform(cell): number {
    const allDeepestItems = this.findDeepestItems(cell.data);

    const filteredItems = allDeepestItems.filter(item => item.attendeeType === AttendeeType.Invitee);

    return filteredItems.length;
  }

  findDeepestItems(data): any[] {
    if ((!data.items || data.items.length === 0) && (!data.collapsedItems || data.collapsedItems.length === 0)) {
      return [data];
    }

    const nestedItems = data.items || data.collapsedItems || [];
    return nestedItems.flatMap(item => this.findDeepestItems(item));
  }
}
