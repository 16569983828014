<dx-data-grid
  #agentgrid
  class="agent-grid__component"
  [dataSource]="dataSource"
  [showBorders]="false"
  [remoteOperations]="true"
  [syncLookupFilterValues]="false"
  [columnAutoWidth]="true"
  [scrollLeft]="true"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [focusedRowEnabled]="false"
  [focusedColumnIndex]="0"
  [autoNavigateToFocusedRow]="false"
  (onRowDblClick)="showContactInfo($event)"
  (onOptionChanged)="onOptionChanged($event)"
  height="100%"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-header-filter [visible]="false"></dxo-header-filter>

  <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>

  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder [allowHierarchicalFields]="true"></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>

  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

  <!-- <dxo-selection mode="multiple"> </dxo-selection> -->

  <!-- <dxo-state-storing [enabled]="true" type="localStorage" [storageKey]="StorageKeys.agentsGrid"></dxo-state-storing> -->

  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate></div>
    </dxi-item>

    <dxi-item location="before" width="100px">
      <div *dxTemplate>
        <h5 class="agent-grid__toolbar-title">Agents</h5>
      </div>
    </dxi-item>

    <dxi-item
      location="after"
      widget="dxButton"
      cssClass="agent-grid__toolbar-control"
      [visible]="Entity.agentAdminister | hasPermission : EntityPermissionActivityKeys.create | async"
      [options]="{ icon: 'add', onClick: addAgent }"
    >
    </dxi-item>

    <dxi-item
      location="after"
      widget="dxDropDownButton"
      cssClass="agent-grid__toolbar-control"
      [options]="{
        icon: 'export',
        items: exportOptions,
        displayExpr: 'description',
        keyExpr: 'value',
        showArrowIcon: false,
        dropDownOptions: { width: 230 }
      }"
    >
    </dxi-item>

    <dxi-item name="columnChooserButton" cssClass="agent-grid__toolbar-control"></dxi-item>

    <dxi-item name="searchPanel" cssClass="agent-grid__toolbar-control"></dxi-item>

    <dxi-item location="after" *var="gridConfigurations$ | async as gridConfigurations">
      <div *dxTemplate>
        <dx-drop-down-box
          #configurationSelectBoxRef
          [(value)]="selectedConfiguration"
          [placeholder]="'Default'"
          [valueExpr]="BaseModelKeys.dbId"
          [displayExpr]="AgentGridConfigurationKeys.name"
          [showClearButton]="true"
          [dataSource]="gridConfigurations"
          [deferRendering]="false"
          [width]="250"
          [dropDownOptions]="{ minHeight: 250, wrapperAttr: { class: 'agent-grid__configurations-list-wrapper' } }"
        >
          <dxi-button name="clear"></dxi-button>

          <dxi-button
            *ngIf="!!selectedConfiguration?.length ?? false"
            name="save"
            location="after"
            [options]="{
              icon: 'save',
              type: 'success',
              stylingMode: 'text',
              elementAttr: {
                class: 'inline-editor-control'
              },
              onClick: saveConfigurationUpdates
            }"
          ></dxi-button>

          <div *dxTemplate="let data of 'content'" class="agent-grid__configurations-list">
            <dx-data-grid
              #configurationsGrid
              class="agent-grid__configurations-list"
              [dataSource]="gridConfigurations"
              [keyExpr]="BaseModelKeys.dbId"
              [showColumnHeaders]="false"
              [showColumnLines]="false"
              [focusedRowEnabled]="false"
              [selectedRowKeys]="selectedConfiguration"
              (onRowClick)="applyConfiguration($event)"
              (onRowInserting)="addConfiguration($event)"
              (onRowRemoving)="removeConfiguration($event)"
              [noDataText]="'No Configurations Exist'"
              height="100%"
            >
              <dxo-editing [useIcons]="true" [allowDeleting]="true" [allowAdding]="true"></dxo-editing>

              <dxi-column [dataField]="AgentGridConfigurationKeys.name">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>

              <dxi-column type="buttons" width="38"></dxi-column>
            </dx-data-grid>
          </div>
        </dx-drop-down-box>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <dxi-column
    [dataField]="AgentKeys.email_addresses"
    dataType="string"
    [caption]="titleList[AgentKeys.email_addresses]"
    [minWidth]="250"
    cellTemplate="emailAddressesCellTemplate"
  >
    <div *dxTemplate="let cell of 'emailAddressesCellTemplate'">
      <ng-container *ngFor="let item of cell.value">
        <p class="agent-grid__grid-row">
          <span [innerHTML]="item.address | highlightFilterSearchTerm : (filterPanelText$ | async)"></span>

          <i *ngIf="item?.is_login" class="dx-icon-agi-key agent-grid__grid-icon"></i>
        </p>
      </ng-container>
    </div>
  </dxi-column>

  <dxi-column [dataField]="BaseModelKeys.dbId" [caption]="'Firebase DB Id'" [visible]="false"> </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_agent_id" [caption]="titleList[AgentKeys.p_agent_id]" [width]="100">
  </dxi-column>

  <dxi-column
    [dataField]="AgentKeys.p_agent_first_name"
    [caption]="titleList[AgentKeys.p_agent_first_name]"
    [minWidth]="150"
  >
  </dxi-column>

  <dxi-column
    [dataField]="AgentKeys.p_agent_last_name"
    [caption]="titleList[AgentKeys.p_agent_last_name]"
    [minWidth]="150"
  >
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.agent_status" [caption]="titleList[AgentKeys.agent_status]" [minWidth]="200">
    <dxo-lookup
      [dataSource]="agentStatuses"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.prospect_status" [caption]="titleList[AgentKeys.prospect_status]" [minWidth]="200">
    <dxo-lookup
      [dataSource]="prospectStatuses"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_mga_id" [caption]="titleList[AgentKeys.p_mga_id]" [minWidth]="200">
    <dxo-lookup
      [dataSource]="mgaList$ | async"
      [allowClearing]="false"
      [displayExpr]="'name'"
      [valueExpr]="'agency_id'"
    >
    </dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.p_agency_id" [caption]="titleList[AgentKeys.p_agency_id]" [minWidth]="200">
    <dxo-lookup
      [dataSource]="agencies$ | async"
      [allowClearing]="true"
      [displayExpr]="'name'"
      [valueExpr]="'agency_id'"
    >
    </dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.agent_type" [caption]="titleList[AgentKeys.agent_type]" [minWidth]="130">
    <dxo-lookup
      [dataSource]="agentTypes"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
      [allowClearing]="false"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.role" [caption]="titleList[AgentKeys.role]" [minWidth]="130"> </dxi-column>

  <dxi-column
    [dataField]="AgentKeys.phone_numbers"
    dataType="string"
    [caption]="titleList[AgentKeys.phone_numbers]"
    [width]="150"
    cellTemplate="phoneNumbersCellTemplate"
  >
    <div *dxTemplate="let cell of 'phoneNumbersCellTemplate'">
      <ng-container *ngFor="let item of cell.value">
        <p class="agent-grid__grid-row" *ngIf="item | phoneNumberMask as phoneNumber">
          <span [innerHTML]="phoneNumber | highlightFilterSearchTerm : (filterPanelText$ | async)"></span>

          <i *ngIf="item?.is_primary" class="dx-icon-agi-key agent-grid__grid-icon"></i>
        </p>
      </ng-container>
    </div>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.is_manager" [caption]="titleList[AgentKeys.is_manager]" width="100">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.is_rmd" [caption]="titleList[AgentKeys.is_rmd]" width="100">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column [dataField]="AgentKeys.christmas_card" [caption]="titleList[AgentKeys.christmas_card]" [visible]="false">
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    [dataField]="AgentKeys.conference_poster"
    [caption]="titleList[AgentKeys.conference_poster]"
    [visible]="false"
  >
    <dxo-lookup
      [dataSource]="TRUE_FALSE_LOOKUP"
      [allowClearing]="false"
      [valueExpr]="LookupKeys.value"
      [displayExpr]="LookupKeys.description"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Auth Stats" [visible]="false">
    <dxi-column
      [dataField]="AgentKeys.first_login_date"
      [caption]="'First Login Date'"
      dataType="date"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column
      [dataField]="AgentKeys.last_login_date"
      [caption]="'Last Login Date'"
      dataType="date"
      [format]="dateFormat"
    ></dxi-column>

    <dxi-column [dataField]="AgentKeys.login_count" [caption]="'Login Count'" dataType="number"></dxi-column>
  </dxi-column>

  <dxi-column
    [dataField]="AgentKeys.addresses"
    dataType="string"
    [caption]="titleList[AgentKeys.addresses]"
    [minWidth]="150"
    cellTemplate="addressesCellTemplate"
  >
    <div *dxTemplate="let cell of 'addressesCellTemplate'">
      <ng-container *ngFor="let item of cell.value">
        <p class="agent-grid__grid-row" *ngIf="item | fullAddress as address">
          <span [innerHTML]="address | highlightFilterSearchTerm : (filterPanelText$ | async)"></span>

          <i *ngIf="item?.is_primary_shipping" class="dx-icon-agi-shipping-address agent-grid__grid-icon"></i>
          <i *ngIf="item?.is_primary_billing" class="dx-icon-agi-billing-address agent-grid__grid-icon"></i>
        </p>
      </ng-container>
    </div>
  </dxi-column>

  <dxi-column name="shippingAddress" caption="Shipping Address" [allowFiltering]="false" [visible]="false">
    <dxi-column
      name="shippingAddress.address1"
      [calculateCellValue]="AddressModelKeys.address1 | calculateAddressFieldDisplayValue : 'shipping'"
      [caption]="'Address 1'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="shippingAddress.address2"
      [calculateCellValue]="AddressModelKeys.address2 | calculateAddressFieldDisplayValue : 'shipping'"
      [caption]="'Address 2'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="shippingAddress.city"
      [calculateCellValue]="AddressModelKeys.city | calculateAddressFieldDisplayValue : 'shipping'"
      [caption]="'City'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="shippingAddress.state"
      [calculateCellValue]="AddressModelKeys.state | calculateAddressFieldDisplayValue : 'shipping'"
      [caption]="'State'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="shippingAddress.zip"
      [calculateCellValue]="AddressModelKeys.zip | calculateAddressFieldDisplayValue : 'shipping'"
      [caption]="'Zip'"
      [allowFiltering]="false"
    ></dxi-column>
  </dxi-column>

  <dxi-column caption="Billing Address" [allowFiltering]="false" [visible]="false">
    <dxi-column
      name="billingAddress.address1"
      [calculateCellValue]="AddressModelKeys.address1 | calculateAddressFieldDisplayValue : 'billing'"
      [caption]="'Address 1'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="billingAddress.address2"
      [calculateCellValue]="AddressModelKeys.address2 | calculateAddressFieldDisplayValue : 'billing'"
      [caption]="'Address 2'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="billingAddress.city"
      [calculateCellValue]="AddressModelKeys.city | calculateAddressFieldDisplayValue : 'billing'"
      [caption]="'City'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="billingAddress.state"
      [calculateCellValue]="AddressModelKeys.state | calculateAddressFieldDisplayValue : 'billing'"
      [caption]="'State'"
      [allowFiltering]="false"
    ></dxi-column>

    <dxi-column
      name="billingAddress.zip"
      [calculateCellValue]="AddressModelKeys.zip | calculateAddressFieldDisplayValue : 'billing'"
      [caption]="'Zip'"
      [allowFiltering]="false"
    ></dxi-column>
  </dxi-column>

  <dxi-column type="buttons" [width]="145" [fixed]="true" [caption]="'Command'">
    <dxi-button
      name="delete"
      icon="trash"
      [visible]="Entity.agentAdminister | hasPermission : EntityPermissionActivityKeys.delete | async"
      [onClick]="handleDeleteAgent"
    ></dxi-button>

    <dxi-button
      hint="Set Sales Goals"
      icon="money"
      [visible]="Entity.agentSalesGoal | hasPermission : EntityPermissionActivityKeys.write | async"
      [onClick]="showSalesGoal"
    >
    </dxi-button>

    <dxi-button
      hint="Generate Agent ID"
      icon="key"
      [visible]="Entity.agentGenerateAgentId | hasPermission : EntityPermissionActivityKeys.write | async"
      [onClick]="generateAgentId.bind(this)"
    >
    </dxi-button>

    <dxi-button
      hint="Show Agent Status Activities"
      [visible]="Entity.agentProspectStatusActivity | hasPermission : EntityPermissionActivityKeys.write | async"
      icon="agi-comment-dots"
      [onClick]="showAgentStatusActivities"
    >
    </dxi-button>
  </dxi-column>
</dx-data-grid>

<ag-shr-modal-window #approveDenyReasonModalRef [title]="'Prospect Status Activity'" [showSaveButton]="false">
  <ag-shr-approve-deny-reasons-grid
    [agentId]="selectedAgentId"
    [canCreate]="Entity.agentProspectStatusActivity | hasPermission : EntityPermissionActivityKeys.create | async"
    [isEditable]="Entity.agentProspectStatusActivity | hasPermission : EntityPermissionActivityKeys.write | async"
    [canDelete]="Entity.agentProspectStatusActivity | hasPermission : EntityPermissionActivityKeys.delete | async"
  ></ag-shr-approve-deny-reasons-grid>
</ag-shr-modal-window>

<ag-shr-agent-sales-goals-modal #salesGoalsModalRef></ag-shr-agent-sales-goals-modal>

<ag-shr-create-agent-modal #createAgentModelRef (onAgentCreated)="reloadGrid()"></ag-shr-create-agent-modal>

<ag-shr-delete-agent-modal #deleteAgentModelRef (onAgentDeleted)="onAgentDeleted($event)"></ag-shr-delete-agent-modal>
